import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Grow from '@material-ui/core/Grow';
import TableRow from '@material-ui/core/TableRow';
import myPic from "./me-cropped-gray-bettter.jpg";

const styles = theme => ({
    paper: {
        width: '70%',
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
        display: 'grid',
        gridGap: '50px',
        gridTemplateColumns: '1fr 1fr',
    },
    imgRaised: {
        boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRounded: {
        borderRadius: "6px !important"
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    table: {
        width: '100%'
    }
});

function createData(name, value, link) {
    return { name, value, link };
}

const rows = [
    createData('Location', 'Salt Lake City, Utah - Willing to relocate'),
    createData('Phone', "(385) 216-0582"),
    createData('Email', 'naylor.orrin@gmail.com'),
    createData('Experience', ' 7 years'),
    createData('GitLab', 'View My Git Lab', 'https://gitlab.com/naylor.orrin'),
    createData('Resume', 'Download', './Resume.pdf')
];
function Content(props) {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Typography color="textSecondary" align="left">
                                About me
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                <div>
                    <Grow in={true}>
                        <img
                            src={myPic}
                            alt="..."
                            className={
                                classes.imgRaised +
                                " " +
                                classes.imgRounded +
                                " " +
                                classes.imgFluid
                            }
                        />
                    </Grow>
                </div>
                <div className={classes.table}>
                    <Grow in={true}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            {row.link ?
                                                (<TableCell align="right"><a href={row.link}>{row.value}</a></TableCell>) :
                                                (<TableCell align="right">{row.value}</TableCell>)}

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grow>
                </div>
            </div>
        </Paper>
    );
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);