import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

const styles = theme => ({
    paper: {
        width: '70%',
        margin: 'auto',
        overflow: 'hidden',
    },
    '@media (min-width: 1000px)': {
        paper: {
            width: '70%',
        }
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
    imgRaised: {
        boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRounded: {
        borderRadius: "6px !important"
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    table: {
        width: '100%'
    }
});

function createData(name, value, link) {
    return { name, value, link };
}
function Education(props) {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Typography color="textSecondary" align="left">
                                Education
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>

                <Timeline lineColor={'#ddd'}>
                    <TimelineItem
                        key="001"
                        style={{ color: '#cc0000' }}
                        dateInnerStyle={{ background: '#cc0000' }}
                    >
                        <h3>BS. Computer Engineering, University of Utah</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="001"
                        style={{ color: '#337ab7' }}
                        dateInnerStyle={{ background: '#337ab7' }}
                    >
                        <h3>Associates of Science, Salt Lake Community College</h3>
                    </TimelineItem>
                    <TimelineItem
                        key="001"
                        style={{ color: '#3D3E6E' }}
                        dateInnerStyle={{ background: '#3D3E6E' }}
                    >
                        <h3>HS, Itineris Early College High School</h3>
                    </TimelineItem>
                </Timeline>
            </div>
        </Paper>
    );
}

Education.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Education);