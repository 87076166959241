import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

const styles = theme => ({
    paper: {
        width: '70%',
        margin: 'auto',
        overflow: 'hidden',
    },
    '@media (min-width: 1000px)': {
        paper: {
            width: '70%',
        }
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
    imgRaised: {
        boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRounded: {
        borderRadius: "6px !important"
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    table: {
        width: '100%'
    }
});

function createData(name, value, link) {
    return { name, value, link };
}
function Content(props) {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Typography color="textSecondary" align="left">
                                Work Experience
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>

                <Timeline lineColor={'#ddd'}>
                    <TimelineItem
                        key="001"
                        dateText="July 2019 – Present"
                        style={{ color: '#74CCC6' }}
                        dateInnerStyle={{ background: '#74CCC6' }}
                    >
                        <h3>Senior Software Engineer, SOLO</h3>
                        <h4><a href="https://gosolo.io">gosolo.io</a></h4>
                        <p>
                            Currently using Tensorflow and Python to build an ML system that can identify features on a home’s roof from satellite imagery. We may be able to automate sixty percent of our manual labor.</p>
                        <p>
                            Used React, NodeJS, and HTML Canvas to build a solar design tool that uses lidar data, satellite imagery, and solar radiance data. Our CAD peopleit to  estimate how many solar panels can fit on a roof and how much power the system will produce. We currently generate about 25,000 proposals a month and my tool is used for every one of them.</p>
                        <p>
                            Built a similar roofing tool that proposalists use to give accurate measurements of a houses roof.</p>
                        <p>
                            I implemented a caching system for our Google Maps to reduce our high cloud costs, this system saves us about $800 a month.</p>
                        <p>Some of my leadership roles:
        <ul>
                                <li>I spend about an hour a day doing code reviews.</li>
                                <li>I help set many of our standard and best practices.</li>
                                <li>I give two or three training a month on various different topics.</li>
                            </ul>
                        </p>
                    </TimelineItem>
                    <TimelineItem
                        key="002"
                        dateText="February 2018 - July 2019"
                        dateInnerStyle={{ background: '#2991d6' }}
                        style={{ color: '#2991d6' }}
                    >
                        <h3>Software Engineer, Webaholics</h3>
                        <h4><a href="https://webaholics.co">webaholics.co</a></h4>
                        <p>
                            I lead/worked(my role changed while I was here) on a team of 2-5 engineers that used React, Nodejs, VueJs, PostgreSQL, and Kubernetes to build a webapp that helped manage our 1.5 million eCommerce listings.  Some of its main functions were:  giving us breakdowns of our sales, helping us more accurately place orders, update our product inventory on Amazon, Walmart, Ebay, and Woocommerce, and helping us create new listings across our distribution channels. While at Webaholics we grew from about 2 million to 4 million dollars in revenue. During this growth we only increased our staff by about 10 percent, in part, because our web app helped us manage the growth.
    </p>
                        <p>
                            I also worked on client sponsored projects. We mostly use PHP/Wordpress. I personally helped push many projects through to launch.
    </p>
                    </TimelineItem>
                    <TimelineItem
                        key="003"
                        dateText="July 2015 - August 2016"
                        style={{ color: '#2991d6' }}
                        dateInnerStyle={{ background: '#2991d6' }}
                    >
                        <h3>Software Engineer, Konka Studios</h3>
                        <p>
                            Lead a team of three to one(just me) C#/Unity3d engineers and a graphic designer. We made a real time massive multiplayer online game called Oceans at War.
    </p>
                        <p>
                            Worked heavily on the C# backend. It was built on a framework called Photon. Everything had to be real time, which made it very challenging. There was a large world map where you could see other players and do battle with them. Also you had a base where you would collect resources and build new ships to do battle.
    </p>
                    </TimelineItem>
                    <TimelineItem
                        key="004"
                        dateText="June 2014 - August 2014"
                        dateInnerStyle={{ background: 'linear-gradient(to bottom,  rgba(78,105,162,1) 0%,rgba(59,89,152,1) 100%)' }}
                        style={{ color: 'rgba(59,89,152,1)' }}
                    >
                        <h3>Software Engineer, Free Online Games</h3>
                        <h4><a href="https://www.freeonlinegames.com/">www.freeonlinegames.com</a></h4>
                        <p>Me and a designer work on a team that used Unity3d to build an in browser motorcycle game.</p>
                    </TimelineItem>
                    <TimelineItem
                        style={{ color: '#e86971' }}
                        dateInnerStyle={{ background: '#e86971' }}
                        key="005"
                        dateText="June  2014 -  Present"
                    >
                        <h3>Software Engineer, Freelancer</h3>
                        <p>Throughout this time period I have consistently picked up small part time projects that last a month-ish. Mostly when I was in High School and when I was on my mission.</p>
                        <p>Throughout my career if don’t have anything to do I still used my spare time to learn anything and everything I can. Over the years I have done side projects with React, Nodejs, Angular, Python, lots of tensorflow, and many other small frameworks/libraries that have gone the way of the Dodo.</p>
                        <p>People sometimes ask me how I became a Senior developer(or have so much experience/knowledge) at such a young age and I always tell them it was from picking up small jobs where the purpose was to learn something, and from using my spare time to teach my self.</p>
                    </TimelineItem>
                </Timeline>
            </div>
        </Paper>
    );
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);