import React from 'react';
import Paperbase from './Paperbase'

function App({history, location}) {
  return (
    <Paperbase history={history} location={location}/>
  );
}

export default App;
