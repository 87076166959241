import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({
    paper: {
        width: '70%',
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
    imgRaised: {
        boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRounded: {
        borderRadius: "6px !important"
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    table: {
        width: '100%'
    },
    card: {
        display: 'flex',
        minHeight: '200px'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '30%',
        minWidth: '300px'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    noUnderline: {
        textDecoration: 'none'
    }
});
function Projects(props) {
    const { classes } = props;

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Typography color="textSecondary" align="left">
                                Projects
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                <a href="https://www.youtube.com/watch?v=dfM3-Qx0uag" class={classes.noUnderline}>
                    <Grow in={true}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cover}
                                image={'https://i9.ytimg.com/vi/dfM3-Qx0uag/mqdefault.jpg?time=1583460855457&sqp=CPjhhvMF&rs=AOn4CLDspz1ZW2HOq3OBH-J_BFBZ2JZujA'}
                                title="Roof Plane Instance Segmentation (AI/ML/Neural Network)"
                            />
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <Typography component="h5" variant="h5">
                                    Roof Plane Instance Segmentation (AI/ML/Neural Network)
          </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Built with Python, TensorFlow, and Numpy Currently building a ML system to identify penetrations on the roof and segment out mounting planes.
                                        PS: I would love to give specifics about how this works and issues we've overcome, but there could be potential NDA issues
          </Typography>
                                </CardContent>
                            </div>
                        </Card>
                    </Grow>
                </a>
                <br></br>
                <a href="https://www.youtube.com/watch?v=tOyw2_XthWs" class={classes.noUnderline}>
                    <Grow in={true}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cover}
                                image={'https://i.ytimg.com/vi/tOyw2_XthWs/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLC1b6BDF8cZWPZpYyB6D9fBAH1i1Q'}
                                title="SOLO Solar Tool"
                            />
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <Typography component="h5" variant="h5">
                                        SOLO Solar tool
          </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Built with React and Canvas this solar tool uses a combination of different satellite images, lidar, and solar radiance 
                                        data to help our CAD designers build an accurate and visually appealing solar design quickly.
          </Typography>
                                </CardContent>
                            </div>
                        </Card>
                    </Grow>
                </a>
                <br></br>
                <a href="https://www.youtube.com/watch?v=qhqickFa8e8" class={classes.noUnderline}>
                    <Grow in={true}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cover}
                                image={'https://i.ytimg.com/vi/qhqickFa8e8/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCrctCrEb5t2HuHCamSnvE6ubIFHg'}
                                title="SOLO Roofing Tool"
                            />
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <Typography component="h5" variant="h5">
                                        SOLO Roofing tool
          </Typography>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Built with React and Canvas this roofing to uses lidar data to help our proposalists
                                    create an accurate and visually appealing roofing proposal
          </Typography>
                                </CardContent>
                            </div>
                        </Card>
                    </Grow>
                </a>
            </div>
        </Paper>
    );
}

Projects.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Projects);